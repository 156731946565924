.piece-Outline {
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: red;
    padding: 5%;
    display: flex;
}

.piece {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.piece-White {
    background: linear-gradient(45deg, rgb(156, 156, 156) 0%, rgba(255,255,255,1) 100%);
}
.piece-Black {
    background: linear-gradient(45deg, rgba(0,0,0,1) 0%, rgb(87, 87, 87) 100%);
}

.crown {
    width: 70%;
    aspect-ratio: 1/1;
    user-select: none;
    filter: invert(10%) sepia(89%) saturate(5619%) hue-rotate(360deg) brightness(129%) contrast(117%);
}
