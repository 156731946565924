.bg-White {
  background: rgb(189, 189, 189);
  background: linear-gradient(
    45deg,
    rgba(189, 189, 189, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.bg-Black {
  background: rgb(41, 41, 41);
  background: linear-gradient(
    45deg,
    rgba(41, 41, 41, 1) 0%,
    rgba(89, 89, 89, 1) 100%
  );
}

.square {
  padding: .25rem;
  display: flex;
}

.square-reversed {
  transform: rotate(180deg);
}
/* 
.square-selected {
  position: relative;
  z-index: 1;
  transition: box-shadow 0.5s ease-in-out;
  box-shadow: 0px 0px 10px 5px rgb(255, 0, 0);
} */

.square-highlighted {
  z-index: 1;
  animation: breath 2s infinite;
}

@keyframes breath {
  0% { box-shadow: 0px 0px 10px 0px rgb(151, 151, 151); }
  50% { box-shadow: 0px 0px 5px 2px var(--primary-color); }
  100% { box-shadow: 0px 0px 10px 0px rgb(151, 151, 151); }
}

@media screen and (min-width: 800px) {
  .square {
    padding: .5rem;
  }
  .square-highlighted {
    z-index: 1;
    animation: breath 2s infinite ease-in-out;
  }
  
  @keyframes breath {
    0% { box-shadow: 0px 0px 10px 0px rgb(151, 151, 151); }
    50% { box-shadow: 0px 0px 14px 4px var(--primary-color); }
    100% { box-shadow: 0px 0px 10px 0px rgb(151, 151, 151); }
  }
}
  