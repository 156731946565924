.container {
  display: flex;
  justify-content: center;
}

.board {
  aspect-ratio: 1/1;
  height: 80vmin;
  display: grid;
  border-color: rgb(27, 27, 27);
  border-width: .5rem;
  border-radius: 10px;
  border-style: solid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
}

.board-reversed {
  transform: rotate(180deg);
}
