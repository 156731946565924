.container {
  display: grid;
  grid-template-columns: 3fr auto 3fr;
  grid-template-rows: auto auto;
  padding: 1rem;
  -webkit-user-select: none;
  user-select: none;
}

.container h2,
.container h3 {
  margin-bottom: 0;
}

.container h2 {
  font-size: 1rem;
}

.guest {
  margin-left: 0.5rem;
}

.icon {
  height: 1.5rem;
  margin: auto;
  margin-right: 0.5rem;
}

.owner {
  grid-column: 1;
  grid-row: 2;
  display: flex;
  margin-left: auto;
  align-items: center;
  margin-right: 0.5rem;
}

.tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  font-size: large;

  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.room {
  grid-column: 2;
  vertical-align: middle;
  border: solid 2px gray;
  padding: 0 0.25rem;
  transition: 0.5s;
}

.room-id {
  grid-column: 2;
  grid-row: 1;
  text-align: center;
  font-size: 0.75rem;
}

.guest {
  grid-column: 3;
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 0.5rem;
}

.placeholder {
  margin-top: auto;
  margin-left: 0.5rem;
  background: linear-gradient(90deg, rgb(56, 56, 56) 0%, rgb(75, 75, 75) 10%, rgb(53, 53, 53) 20%);
  border-radius: 5px;
  background-size: 200%;
  width: 50%;
  height: 90%;
  animation: loading 2s linear infinite;
}

.instructions {
  margin: 1rem 5rem;
}

.instructions ol {
  margin: 0;
  padding-left: 1rem; 
}

.waiting {
  margin: 1rem 5rem;
  text-align: center;
}

.button {
  display: flex;
  border-color: transparent;;
  grid-column-start: 1;
  grid-column-end: 4;
  margin-top: 2rem;
  color: white;
  margin: auto;
  background-color: var(--primary-color);
  border-radius: 5px;
  font-size: 1.25rem;
}


@keyframes loading {
  0% {
    background-position: 100% 0
  }
  100% {
    background-position: -100% 0
  }
}

@media screen and (min-width: 600px) {
  .icon {
    height: 2.5rem;
  }

  .container h2 {
    font-size: 2rem;
  }
  .container h3 {
    font-size: 1rem;
  }

  .room {
    position: relative;
    display: inline-block;
  }

  .room:hover .tooltip-text {
    visibility: visible;
  }

  .room:hover {
    cursor: pointer;
    background-color: var(--primary-color);
    border-color: black;
    transition: 0.5s;
  }

  .placeholder {
    width: 10rem;
  }
}
