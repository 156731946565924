.container {
  text-align: center;
  display: flex;
  justify-content: center;
  -webkit-user-select: none; /* Safari */
  user-select: none; /* Standard syntax */
}

.container h2 {
  font-size: medium;
  padding: 10px;
  margin: 0;
  text-align: center;
}

.opponent-Text {
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(95, 95, 95) 10%, rgb(255, 255, 255) 20%);
  background-size: 200%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: loading 2.5s linear infinite;
}

@keyframes loading {
  0% {
    background-position: 100% 0
  }
  100% {
    background-position: -100% 0
  }
}

@media screen and (min-width: 800px) {
  .container h2 {
    font-size: x-large;
  }

  @keyframes loading {
    0% {
      background-position: 100% 0
    }
    100% {
      background-position: -100% 0
    }
  } 
}