.valid-move {
  width: 40%;
  height: 40%;
  border-radius: 100%;
  margin: auto;
  background: rgb(186, 186, 186);
  background: linear-gradient(
    45deg,
    rgba(186, 186, 186, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  animation: pulse 2s infinite;
}
/* 
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.10);
  }
  100% {
    transform: scale(1);
  }
} */

/* .valid-move {
  aspect-ratio: 1/1;
  border-radius: 100%;
  margin: 30%;
  background: rgb(186, 186, 186);
  background: linear-gradient(
    45deg,
    rgba(186, 186, 186, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.valid-move::before {
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  margin-left: -50%;
  margin-top: -50%;
  border-radius: 100%;
  background-color: white;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.valid-move::after {
  content: "";
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin-top: -150%;
  background: rgb(186, 186, 186);
  background: linear-gradient(
    45deg,
    rgba(186, 186, 186, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
} */
