:root {
  --primary-color: #ff3300;
  --background-color: rgb(44, 44, 44);
}

html,
body {
  position: fixed;
  overflow: hidden;
}

body {
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  color: white;
  overflow: hidden;
}

html {
  font-size: 100%;
  overflow-y: hidden;
  overscroll-behavior: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
  caret-color: white;
}